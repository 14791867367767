<template>
  <v-container fluid grid-list-lg>
    <v-card :loading="loading" outlined>
      <v-toolbar dense flat :color="$style.purchase.titlebar">
        <v-app-bar-nav-icon v-if="!VendorId" @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-app-bar-nav-icon>

        <v-toolbar-title> Purchase </v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-row justify="end">
          <v-col class="shrink">
            <v-btn @click="add" outlined rounded v-if="checkRightStatus(6)" raised small>
              <v-icon small>add</v-icon> New
            </v-btn>
          </v-col>
          <v-col class="shrink" v-if="PurchaseDetails.length !== 0">
            <v-btn @click="openRunning" outlined class="ml-2" rounded v-if="checkRightStatus(6)" raised small>
              Running Purchase
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>

      <!-- <v-card-title>
        <v-flex xs12 sm4>
          <v-text-field v-model="search" outlined prepend-inner-icon="search" rounded dense label="Search" single-line
            hide-details clearable></v-text-field>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex v-if="!showAll" sm4 xs12>
          <DateTimePicker defaultRange="since60DaysAgo" :range="true" :hideTime="true" v-model="dateTime" @submit="getList()" />
        </v-flex>
      </v-card-title> -->

      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-toolbar dense elevation="0">

          <v-text-field rounded v-model="search" outlined prepend-inner-icon="search" autofocus dense label="Search" single-line
            hide-details clearable></v-text-field>

          <!-- <v-overflow-btn :items="dropdown_font" label="Select font" hide-details class="pa-0"></v-overflow-btn> -->

          
            <v-divider vertical class="mx-1"></v-divider>

            <v-btn-toggle v-model="purchaseStatus" @change="getList()"  color="deep-purple accent-3" small group>
              <v-btn value="all">
                <span>All</span>

               
              </v-btn>

              <v-btn value="open">
                <span >Open</span>

               
              </v-btn>

              <v-btn value="paid">
                <span >Paid</span>

              
              </v-btn>
              
            </v-btn-toggle>




            <v-spacer></v-spacer>




          <v-spacer></v-spacer>
          <DateTimePicker defaultRange="since60DaysAgo" :range="true" :hideTime="true" v-model="dateTime" @submit="getList()" />
        </v-toolbar>
        <v-divider></v-divider>
      </div>

      <v-card-text class="mb-0 pb-0" v-else>
        <v-row>
          <v-col cols="12" md="3" sm="12">
            <v-text-field v-model="search" outlined prepend-inner-icon="search" rounded dense label="Search" single-line
              hide-details clearable>
            </v-text-field>
          </v-col>
          <v-spacer> </v-spacer>
          <v-col cols="12" md="4" sm="12" v-if="!showAll">
            <DateTimePicker defaultRange="since60DaysAgo" :range="true" :hideTime="true" v-model="dateTime" @submit="getList()" />
          </v-col>
        </v-row>
      </v-card-text>



      <v-card-text>
        <v-data-table :headers="headers" :items="Purchases" @current-items="updateTotal" :search="search"
          :sort-desc="sortDesc" :loading="loading" :sort-by="sortBy" :page.sync="page" :items-per-page="itemsPerPage">
          <template v-slot:item="{ item }">
            <tr class="pointer">
              <!-- <v-btn color="success" @click="fixAvgCost(item)">Fix Detail</v-btn> -->
              <td class="text-left" @click="viewPurchase(item)">
                {{ item.date | moment("MM/DD/YYYY") }}
              </td>
              <td class="text-left" @click="viewPurchase(item)">
                <span class="pr-1">{{ item.localId }}</span>
                <v-chip color="red lighten-3" label small v-if="item.due === 0" text-color="red darken-4">Paid</v-chip>
              </td>
              <td class="text-left" v-if="!VendorId" @click="viewPurchase(item)">
                {{ item.Vendor.name }}
              </td>
              <td class="text-left" @click="viewPurchase(item)">
                {{ item.referenceNumber }}
              </td>
              <td class="text-right" @click="viewPurchase(item)">
                {{ item.total | currency }}
              </td>
              <td class="text-right" @click="viewPurchase(item)">
                {{ item.due | currency }}
              </td>
              <td class="justify-end align-center text-right">
                <v-menu bottom left offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="menu-button">
                    <v-list-item @click="viewPurchase(item)">
                      <v-list-item-title>View Purchase</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="viewPackingSlip(item)">
                      <v-list-item-title>View Packing Slip</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="!VendorId"></v-divider>
                    <v-list-item v-if="!VendorId" @click="viewProfile(item)">
                      <v-list-item-title>Profile</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="editPurchase(item)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deletePurchase(item)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="createInvoice(item)">
                      <v-list-item-title>Create Invoice</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:body.append="{ headers }" v-if="Purchases.length > 0">
            <tr :style="{ 'background-color': $style.listTotal.filter }">
              <td :colspan="headers.length - 3" class="text-right font-weight-bold">
                List Total
              </td>
              <td class="text-right font-weight-bold">
                {{ total | currency }}
              </td>
              <td class="text-right font-weight-bold">
                {{ balance | currency }}
              </td>
              <td></td>
            </tr>
            <tr :style="{ 'background-color': $style.listTotal.result }">
              <td :colspan="headers.length - 3" class="text-right font-weight-bold">
                Result Total
              </td>
              <td class="text-right font-weight-bold">
                {{ fullTotal | currency }}
              </td>
              <td class="text-right font-weight-bold">
                {{ fullBalance | currency }}
              </td>
              <td></td>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no
            results.</v-alert>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import purchaseService from "../service";
const DateTimePicker = () => import("@/components/DateTimePicker");
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  props: {
    VendorId: {
      default: null,
    },
    showAll: {
      default: false,
    },
    due: {
      default: false,
    },
  },
  name: "purchase-list",
  data() {
    return {
      purchaseStatus: 'all',
      fullTotal: 0,
      fullBalance: 0,
      total: 0,
      balance: 0,
      fab: false,
      dateTime: {
        start: this.$moment()
            .startOf("days")
            .subtract(60, "days")
            .toISOString(),
        end: this.$moment().endOf("day").toISOString(),
      },
      createDialog: false,
      selectedPayee: null,
      headers: [],
      Purchases: [],
      search: null,
      loading: true,
      page: 1,
      sortBy: "date",
      pageCount: 0,
      sortDesc: true,
      itemsPerPage: 10,
    };
  },
  watch: {
    VendorId: {
      immediate: true,
      handler() {
        this.getList();
      },
    },
  },
  created() {
    this.loadHeader();
    this.$events.listen("purchaseListEvent", () => this.getList());
    // document.title = "smplsale - Purchase List";
  },
  beforeDestroy() {
    this.$events.remove("purchaseListEvent");
  },
  components: {
    DateTimePicker,
  },
  computed: {
    ...mapGetters("global", [
      "currentUser",
      "checkRightStatus",
      "currentBusiness",
    ]),
    ...mapGetters("purchase", ["PurchaseDetails", "getEditPurchaseId"]),
  },
  methods: {
    ...mapActions("purchase", ["ResetPurchase"]),
    fixAvgCost(purchase) {
      console.log('i call this', purchase.id)
      purchaseService.fixAvgCost(purchase.id).then(result => {
        console.log('update balance result', result)
        // this.getList()
      }).catch(err => {
        console.log(err)
      })
    },
    updateTotal(val) {
      this.total = 0;
      this.balance = 0;
      val.map((row) => {
        this.total += row.total;
        this.balance += row.due;
      });
    },
    openRunning() {
      if (this.getEditPurchaseId) {
        this.$router.push({
          path: `/inventory/purchase/${this.getEditPurchaseId}/edit`,
        });
      } else {
        this.$router.push({ path: `/inventory/purchase/create` });
      }
    },
    add() {
      // console.log("im here", this.PurchaseDetails);
      if (this.PurchaseDetails.length !== 0) {
        this.$swal({
          title: "Are you sure?",
          text: "There is an running purchase you want to clear it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            this.ResetPurchase();
            if (this.VendorId) {
              this.$router.push({
                path: `/inventory/purchase/create?VendorId=${this.VendorId}`,
              });
            } else {
              this.$router.push({ path: `/inventory/purchase/create` });
            }
          }
        });
      } else {
        if (this.VendorId) {
          this.$router.push({
            path: `/inventory/purchase/create?VendorId=${this.VendorId}`,
          });
        } else {
          this.$router.push({ path: `/inventory/purchase/create` });
        }
      }
    },
    viewProfile(val) {
      this.$router.push({ path: `/inventory/vendor/${val.VendorId}/profile` });
    },
    // viewPurchase(purchase) {
    //   const routeData = this.$router.resolve({
    //     path: `/global/purchase/${purchase.id}/view`,
    //   });
    //   window.open(routeData.href, "newwindow", "width=850, height=1100");
    // },
    viewPurchase(purchase) {
      const hashId = hashids.encode(purchase.id);
      const routeData = this.$router.resolve({
        path: `/global/purchase/${hashId}/view`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    viewPackingSlip(purchase) {
      const hashId = hashids.encode(purchase.id);
      const routeData = this.$router.resolve({
        path: `/global/purchase/${hashId}/packingSlip`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    loadHeader() {
      this.headers = [];
      this.headers.push({
        text: this.$t("labels.date"),
        align: "left",
        value: "date",
        sortable: true,
      });

      this.headers.push({
        text: "Purchase Number",
        align: "left",
        value: "localId",
        sortable: true,
      });

      if (!this.VendorId) {
        this.headers.push({
          text: "Vendor Name",
          value: "Vendor.name",
          align: "left",
          sortable: true,
        });
      }

      this.headers.push({
        text: "Reference Number",
        align: "left",
        value: "referenceNumber",
        sortable: true
      });

      this.headers.push({
        text: "Total",
        value: "total",
        align: "right",
        sortable: true,
      });

      // this.headers.push({
      //   text: "Payment",
      //   value: "payment",
      //   align: "right",
      //   sortable: true
      // });
      this.headers.push({
        text: "Due",
        value: "due",
        align: "right",
        sortable: true,
      });
      this.headers.push({
        text: "",
        value: "action",
        sortable: false,
      });
    },
    getList() {
      this.loading = true;
      let where = {};
      if (!this.showAll) {
        where = {
          start: this.dateTime.start,
          end: this.dateTime.end,
        };
      }

      if (this.purchaseStatus === 'open') {
        where.due = true
      }

      if (this.purchaseStatus === 'paid') {
        where.paid = true
      }

      if (this.purchaseStatus === 'all') {
        delete where.paid
        delete where.due
      }
      if (this.VendorId) {
        where.VendorId = this.VendorId;
      }

      if (this.due) {
        where.due = true;
      }
      return purchaseService.getAll(where).then((response) => {
        console.log("purchase", response);
        this.loading = false;
        this.Purchases = response.data;
        this.fullTotal = 0;
        this.fullBalance = 0;
        this.Purchases.map((row) => {
          this.fullTotal += row.total;
          this.fullBalance += row.due;
        });
        return response;
      });
    },
    editPurchase(purchase) {
      // // console.log('ddf', purchase)
      this.$router.push({ path: `/inventory/purchase/${purchase.id}/edit` });
    },
    deletePurchase(purchase) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          purchaseService
            .delete(purchase.id)
            .then((response) => {
              if (response.data) {
                this.$swal("Deleted!", "Purchase has been deleted.", "success");
                this.getList();
                this.$events.emit("VendorProfileEvent");
              }
            })
            .catch((error) => {
              console.log('error', error)
              this.$swal({
                title: "Delete Purchase",
                text: error.data.message,
                icon: "error",
              });
            });
        }
      });
    },
    createInvoice(purchase) {
      this.$swal({
        title: "Are you sure?",
        text: "You want to create invoice with this purchase?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          purchaseService
            .getById(purchase.id)
            .then((obj) => {
              const passData = obj.data.PurchaseDetails;

              this.$router.push({
                name: "invoice-create",
                params: { passData: passData, type: "purchase" },
              });
            })
            .catch((err) => {
              console.log('got error', err)
            });

          // console.log('test consver purchase', purchase)
        }
      });
    },
  },
};
</script>
