<template>
  <v-container grid-list-lg fluid>
    <v-layout column>
      <v-flex>
        <v-card outlined>
          <v-toolbar dense flat :color="$style.purchase.titlebar">
            <v-toolbar-title>Edit Purchase </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-row justify="end">
              <v-col class="shrink">
                <v-btn
                  color="pink" :dark="PurchaseDetails.length === 0 ? false : true"
                  :loading="loading"
                  small
                  :disabled="PurchaseDetails.length === 0"
                  @click="savePurchase"
                  >{{ purchaseTotal | currency }} Update</v-btn
                >
              </v-col>
              <v-col class="shrink">
                <v-btn small text @click.stop.prevent="cancel">Cancel</v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-card-text>
            <v-layout row wrap>
              <v-flex grow>
                <AutoComplete v-model="selectedVendor" :initValue="initValue" />
              </v-flex>
              <v-flex shrink>
                <DateSelector v-model="purchaseDate" :initValue="purchaseDate"/>
              </v-flex>
              <v-flex>
                <v-text-field label="Reference Number" outlined dense hide-details v-model="purchaseRefNumber"></v-text-field>
              </v-flex>
              <v-flex shrink>
                <v-btn color="info" height="40px" @click="noteDialog = true">
                  add Note
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex>
        <!-- <v-btn color="success" @click="fix">Fix Detail</v-btn> -->
        <ProductSearchBar @submit="addRow" />
      </v-flex>
      <v-flex>
        <Cart />
      </v-flex>
    </v-layout>
    <v-dialog persistent v-model="noteDialog" width="500">
      <v-card>
        <ModelTitle title="Add Note" @close="noteDialog = false" />
        <v-container grid-list-md>
          <v-layout row align-center>
            <v-flex>
              <v-textarea
                outlined
                dense
                hide-details
                auto-grow
                v-model="purchaseNote"
                :label="$t('labels.note')"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="noteDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import purchaseService from "../service";
import ProductSearchBar from "@/modules/Inventory/Purchase/components/ProductSearchBar";
import AutoComplete from "@/modules/Inventory/Vendor/components/AutoComplete";
import DateSelector from "@/components/DateSelector";
import Cart from "@/modules/Inventory/Purchase/components/Cart";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "edit-purchase",
  data() {
    return {
      loading: false,
      id: null,
      initValue: null,
      selectedVendor: null,
      dialogCost: 0,
      noteDialog: false,
      editIndex: null,
      createDialog: false,
      search: null,
      focusable: false,
      serialNumber: null,
      selectedProduct: null,
      products: [],
      // headers: [
      //   {
      //     text: "Name",
      //     align: "left",
      //     value: "productDetail",
      //     sortable: true,
      //   },
      //   {
      //     text: "Serial Number",
      //     align: "left",
      //     value: "serialNumber",
      //     sortable: true,
      //   },
      //   {
      //     text: "Quantity",
      //     align: "center",
      //     value: "qty",
      //     sortable: true,
      //   },
      //   {
      //     text: "Amount",
      //     align: "right",
      //     value: "rate",
      //     sortable: true,
      //   },
      //   {
      //     text: "Total",
      //     align: "right",
      //     value: "total",
      //     sortable: true,
      //   },
      // ],
    };
  },
  watch: {
    selectedVendor(val) {
      this.SetVendor(val);
    },
  },
  components: {
    Cart,
    ProductSearchBar,
    AutoComplete,
    DateSelector,
  },
  created() {
    document.title = "smplsale - Edit Purchase";
    this.loadData();
    // this.SetPurchaseDate(this.$moment().toISOString());
  },
  computed: {
    ...mapGetters("purchase", [
      "PurchaseDetails",
      "Purchase",
      "purchaseTotal",
      "getImeiNumberDialog",
			"getPurchaseDate",
      "getPurchaseNote",
      "getPurchaseReferenceNumber"
    ]),
    ...mapGetters("global", ["currentBusiness"]),
    purchaseDate: {
      get: function () {
        return this.getPurchaseDate;
      },
      set: function (val) {
        this.SetPurchaseDate(val);
      },
    },
    purchaseNote: {
      get: function () {
        return this.getPurchaseNote;
      },
      set: function (val) {
        this.SetPurchaseNote(val);
      },
    },
    purchaseRefNumber: {
      get: function () {
        return this.getPurchaseReferenceNumber;
      },
      set: function (val) {
        this.SetPurchaseReferenceNumber(val);
      },
    },
  },
  methods: {
    ...mapActions("purchase", [
      "AddPurchaseDetail",
      "SetPurchaseDate",
      "SetVendor",
      "loadPurchase",
      "SetImeiNumberDialog",
      "SetSelectedProduct",
      "SetEditImeiNumberList",
      "ResetPurchase",
      "SetPurchaseNote",
      "SetPurchaseReferenceNumber"
    ]),
    fix() {
      // console.log('invoice detail', this.InvoiceDetails)
      this.PurchaseDetails.map(row => {
        // console.log('row', row)
        row.unitCountInBox = row.Product.unitCountInBox
        row.boxQty = row.qty
        row.qty = parseFloat(row.boxQty) * parseFloat(row.unitCountInBox)
        row.total = row.boxQty * parseFloat(row.cost)
        row.cost = row.total / row.qty
        // console.log('row', row.Product.unitCountInBox,row.qty,row.price, row.total)
      })
    },
    async loadData() {
      const { id } = this.$route.params;

      await this.loadPurchase(id);
			if (!this.getPurchaseDate) {
				this.SetPurchaseDate(this.$moment().toISOString());
			}
    	// this.SetPurchaseDate(this.getPurchaseDate);
      // // console.log('product', this.Purchase)
      this.initValue = this.Purchase.Vendor;
      this.id = id;
      // return purchaseService.getById(id).then(response => {
      //   // console.log("purchase", response);
      //   this.loadPurchase(response.data);
      //   this.initValue = response.data.Vendor
      //   this.id = response.data.id
      //   // this.SetEditInvoiceId(response.data.id);

      //   return response;
      // });
    },
    cancel() {
      this.ResetPurchase();
      this.$router.go(-1);
    },
    savePurchase() {
      if (!this.Purchase.VendorId) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: "Vendor is not selected!",
          });
      } else {
        if (this.currentBusiness.metadata && this.currentBusiness.metadata.lockPurchaseReferenceNumber && (!this.purchaseRefNumber || this.purchaseRefNumber.length < 4)) {
          this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: "Please enter reference number!",
          });
          return true
        }
        this.loading = true;
        let newPurchase = this.Purchase;
        newPurchase.PurchaseDetails = this.PurchaseDetails;
        return purchaseService
          .update(this.id, newPurchase)
          .then(() => {
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "success",
                title: "Purchase Was Updated!",
              });
            this.ResetPurchase();
            this.cancel();
            // this.$router.push("/inventory/purchase/list");
            this.loading = false;
          })
          .catch((error) => {
            // console.log("errorerror", error);
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "error",
                title: error.data.message,
              });
          });
      }
    },
    addRow(val) {
      // console.log("in create add row", val);
      if (val.trackSerialNumber) {
        this.SetEditImeiNumberList({
          type: "new",
        });
        this.SetSelectedProduct(val);

        this.SetImeiNumberDialog(true);
      } else {
        this.AddPurchaseDetail({
          ProductId: val.id,
          name: val.Bar.name,
          cost: val.Bar.cost,
          total: val.Bar.total,
          qty: val.Bar.qty,
          Product: val,
        });
        // this.emptyEntryBar();
      }
    },
  },
};
</script>

<style scoped></style>
