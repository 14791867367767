<template>
  <div>
    <IMEINumberDialog @close="getfocusToProduct" />
    <Product :createDialog="createProductDialog" @close="createProductDialog = !createProductDialog"
      @submit="getProduct">
    </Product>
    <v-card outlined>
      <v-card-text>
        <v-layout row wrap align-center justify-space-between fill-height>
          <v-flex xs12 sm3>
            <v-autocomplete v-model="selectedProduct" :items="Products"  ref="productSearch" 
              return-object single-line outlined autofocus prepend-inner-icon="search" rounded auto-select-first
              allow-overflow dense :search-input.sync="phoneSearch" hide-details clearable
              :label="$t('labels.searchProduct')" :filter="customFilter">
              <template slot="selection" slot-scope="{ item }">
                <span>{{ item.name }}</span>
              </template>
              <template slot="item" slot-scope="{ item }">
                <div v-html="$highlight(item.name, phoneSearch)">{{ item.name }}</div>
                <!-- <span>{{ item.name }}</span> -->
                <v-spacer></v-spacer>
                <v-chip v-if="item.trackInventory" :color="item.trackSerialNumber
                  ? 'light-blue darken-1'
                  : 'teal darken-1'
                  " dark>
                  <v-avatar left class="light-blue darken-2" v-if="item.trackSerialNumber">
                    <v-icon small>mdi-barcode-scan</v-icon>
                  </v-avatar>
                  <v-avatar left v-else class="teal darken-3">
                    <v-icon small>mdi-package-variant-closed</v-icon>
                  </v-avatar>
                  <span>{{ item.balance }}</span>
                </v-chip>
              </template>
              <template v-slot:append-item>
                <v-divider class="mb-2"></v-divider>
                <v-list-item @click="createProductDialog = true">
                  <v-list-item-content>
                    <v-list-item-title>Create New Product</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>

          </v-flex>

          <v-flex xs12 sm3>
            <v-text-field @focus="$event.target.select()" :disabled="lockProductName" label="Item Name"
              @keyup.enter="$refs.boxQty.focus" v-model="name" outlined dense hide-details autocomplete
              ref="purchaseName"></v-text-field>
          </v-flex>

          <!-- <v-flex sm1
            v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">
            <v-text-field class="centered-input" @focus="$event.target.select()" ref="boxQty" label="Box Qty" outlined
              dense type="number" hide-details @keyup.enter="$refs.purchaseQty.focus" v-model="boxQty"></v-text-field>
          </v-flex> -->
          <v-flex xs12 sm1>
            <v-text-field class="centered-input" @focus="$event.target.select()" ref="purchaseQty" label="Quantity"
              outlined dense @keyup.enter="moveToPrice" hide-details autocomplete v-model="qty"></v-text-field>
          </v-flex>
          <v-flex xs12 sm2>
            <v-text-field class="right-input" @focus="$event.target.select()" label="Cost" outlined
              @keyup.enter="addRow" dense ref="unitCost" hide-details v-model="cost"></v-text-field>
          </v-flex>
          <v-flex xs12 sm2>
            <v-text-field class="right-input" @focus="$event.target.select()" label="Total" disabled outlined readonly
              ref="extAmount" dense hide-details autocomplete v-model="total"></v-text-field>
          </v-flex>
          <v-flex xs12 sm3
            v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.updateProductPriceOnPurchase">
            <v-text-field class="right-input" @focus="$event.target.select()" label="Selling Price" outlined
              ref="sellingPrice" dense hide-details autocomplete v-model="sellingPrice"></v-text-field>
          </v-flex>
          <v-flex xs12 sm3 v-if="selectedProduct && selectedProduct.trackSerialNumber && currentBusiness && currentBusiness.metadata && currentBusiness.metadata.updateSerializeProductStatus">
            <v-select dense outlined :items="serialStatus" v-model="selectedStatus" label="Status" item-text="name"
              return-object></v-select>
          </v-flex>
          <v-flex d-flex xs12 sm1>
            <v-btn color="info" large @click="addRow" :disabled="!selectedProduct">
              add
            </v-btn>
          </v-flex>
        </v-layout>
        <!-- <div
          v-if="selectedProduct && currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory"
          class="text-center">The box contains <span class="font-weight-bold">{{ selectedProduct.unitCountInBox
            }}</span> Units
          priced at <span class="font-weight-bold">{{
            selectedProduct.cost | currency }}</span> in total, with a unit price of <span class="font-weight-bold">{{
              selectedProduct.boxPrice | currency }}</span> </div> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import productService from "@/modules/Product/service.js";
import purchaseService from "../service";
import { mapActions, mapGetters } from "vuex";
const IMEINumberDialog = () =>
  import("@/modules/Inventory/Purchase/components/IMEINumberDialog");
const Product = () => import("@/modules/Product/components/Create");
import _ from 'lodash';

export default {
  data() {
    return {
      sellingPrice: null,
      name: null,
      qty: 1,
      cost: 0,
      boxQty: 1,
      selectedProduct: null,
      createProductDialog: false,
      total: 0,
      Products: [],
      serialStatus: ['available', 'onHold', 'repair'],
      selectedStatus: 'available',
      phoneSearch: null,
    };
  },
  watch: {
    phoneSearch: _.debounce(function (val) {

      if (val.length > 2) {
        this.getProduct();
      }

    }, 300),

    selectedProduct(val) {
      if (val) {
        this.name = val.name;
        this.qty = 1
        this.sellingPrice = val.price
        this.getProductLastPrice(val);
        setTimeout(() => {
          this.$refs.purchaseName.focus();
        }, 300);
        // this.cost = val.cost;
        // this.$nextTick(() => {
        //   this.$refs.purchaseQty.focus();
        // });
      }
    },
    cost(val) {
      this.total = val * this.qty;
    },
    boxQty(val) {
      if (this.selectedProduct) {
        this.qty = val * this.selectedProduct.unitCountInBox
      }

    },
    qty(val) {
      // console.log("this.cost * val;", this.cost * val);
      this.total = this.cost * val;
    },
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    lockProductName() {
      if (!this.currentBusiness.metadata.hasOwnProperty('lockProductName')) {
        return true
      } else if (this.currentBusiness.metadata.lockProductName) {
        return true
      }
      return false
    }
  },
  components: {
    Product,
    IMEINumberDialog,
  },
  created() {
    this.getProduct();
  },
  methods: {
    ...mapActions("purchase", ["SetSelectedProduct", "SetImeiNumberDialog"]),
    customFilter(item, queryText) {
      if (!queryText) return true;
      const terms = queryText.split(' ').filter(Boolean); // Split input by spaces
      const regex = new RegExp(terms.map(term => `(?=.*${term})`).join(''), 'i'); // RegExp to match terms in any order
      return regex.test(item.name);
    },
    getfocusToProduct() {
      setTimeout(() => {
        this.$refs.productSearch.focus();
      }, 300);
    },
    moveToPrice() {
      this.$refs.unitCost.focus();
    },
    moveToQty() {
      if (this.selectedProduct) this.$refs.purchaseQty.focus();
    },
    // filterItems(item, queryText) {
    //   return item.name.toLowerCase().includes(queryText.toLowerCase());
    // },
    emptyEntryBar() {
      this.qty = 1;
      this.boxQty = 1;
      this.cost = 0;
      this.totalCost = 0;
      this.Products = [];
      this.name = null;
      this.sellingPrice = null,
        this.selectedStatus = 'available',
        // this.serialNumberList = [];
        // this.newEntry = true;
        // // console.log('selectedProduct', this.selectedProduct)
        this.$refs.productSearch.focus();

      this.selectedProduct = null;

      // setTimeout(() => {
      //   this.$refs.productSearch.focus();
      // }, 300);
    },
    getProductLastPrice(obj) {
      return purchaseService
        .getLastPurchase({ ProductId: obj.id })
        .then((response) => {
          // console.log("getProductLastPrice", response);
          if (response.data) {
            this.cost = parseFloat(response.data.cost)
          } else {

            this.cost = parseFloat(obj.cost) | 0;
            console.log('obj', this.cost, obj.cost)
          }

          return response;
        });
    },
    getProduct() {
      this.createProductDialog = false;
      return productService.getAll({ search: this.phoneSearch, hideParent: true }).then((result) => {
        // // console.log("productService", result);
        this.Products = result.data;
      });
    },
    updateProduct() {
      productService.update(this.selectedProduct.id, { price: this.sellingPrice })
    },
    addRow() {
      if (!this.selectedProduct) return false;
      if (this.currentBusiness?.metadata?.updateProductPriceOnPurchase) {
        this.updateProduct()
      }

      this.selectedProduct.Bar = {
        name: this.name,
        cost: this.cost,
        total: this.total,
        qty: this.qty,
        boxQty: this.boxQty,
        unitCountInBox: this.selectedProduct.unitCountInBox
      };
      if (this.selectedProduct.trackSerialNumber) {
        this.selectedProduct.Bar.status = this.selectedStatus
      }
      // // console.log("selectedProduct", this.selectedProduct);
      this.$emit("submit", this.selectedProduct);
      this.emptyEntryBar();
    },
  },
};
</script>
