<template>
  <div>
    <v-card outlined>
      <!-- <v-btn color="success" @click="fix()">text</v-btn> -->
      <v-card-text>
        <v-layout row wrap align-center>
          <v-flex shrink
            v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.serializeProduct">
            <ReturnPhone @submit="insertReturn" />
          </v-flex>
          <v-flex shrink>
            <span class="title">{{ PurchaseDetails.length }}</span>
            <v-icon small>mdi-view-list</v-icon>
          </v-flex>
          <v-flex shrink>
            <span class="title">{{ totalQty }}</span>
            <v-icon small>mdi-cart</v-icon>
          </v-flex>
          <!-- <v-flex shrink>
            <span class="title">{{ totalQty }}</span>
            <v-icon small>mdi-package-variant-closed</v-icon>
          </v-flex> -->
          <v-spacer> </v-spacer>
          <v-flex xs12 sm4>
            <v-text-field v-model="search" outlined prepend-inner-icon="search" rounded dense label="Search" single-line
              hide-details clearable>
            </v-text-field>
          </v-flex>
        </v-layout>
        <v-data-table :search="search" hide-default-footer :itemsPerPage="500" :headers="headers"
          :items="PurchaseDetails">
          <template v-slot:item="{ item, index }">
            <tr v-if="!item.delete" :style="{ 'background-color': activeColor(item) }">
              <td>
                <v-btn icon v-if="!item.status" @click="deleteItem(item, index)">
                  <v-icon color="red">delete</v-icon>
                </v-btn>
              </td>
              <td>
                <div><span v-if="item.Product && item.Product.localId">{{ item.Product.localId }} | </span>{{ item.name }}</div>
                <!-- <div>{{ item.boxQty }}</div>
                <div>/{{ item.cost * item.unitCountInBox }} = {{ item.cost * item.unitCountInBox * item.boxQty }}</div> -->
                <v-row v-if="item.receivedAt">
                  <v-col class="shrink py-0">
                    <v-btn :loading="removeLoading" @click="remove(item)" color="red" icon>
                      <v-icon>remove</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="py-0">
                    <div>
                      {{ item.receivedAt | moment("MM/DD/YYYY hh:mm a") }}
                    </div>
                    <div>{{ item.returnNote }}</div>
                  </v-col>
                </v-row>
                <v-chip class="ml-2" small color="green" v-if="item.status && item.qty > 0" text-color="white">
                  Sold
                </v-chip>
              </td>
              <td class="text-left"
                v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.serializeProduct">
                {{ item.imeiNumber }}
              </td>
              <!-- <td class="text-center"
                v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">
                <v-text-field v-model="item.boxQty" :disabled="item.imeiNumber ? true : false" outlined
                  @blur="checkPrice(item)" type="number" class="centered-input" hide-details dense
                  @focus="$event.target.select()"></v-text-field>
              </td> -->

              <td class="text-center"><v-text-field v-model="item.qty" :disabled="item.imeiNumber ? true : false"
                  outlined @blur="checkPrice(item)" type="number" class="centered-input" hide-details dense
                  @focus="$event.target.select()"></v-text-field>
              </td>
              <td class="text-right" v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.updateSerializeProductStatus">
                <v-select dense outlined :items="serialStatus" v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.serializeProduct && item.metadata" v-model="item.metadata.serialStatus"
                  item-text="name" return-object  hide-details @focus="$event.target.select()"></v-select>
              </td>
              <td v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">
                <div class="text-center">{{ item.unitCountInBox }}</div>
              </td>
              <td class="text-right">
                <v-text-field v-if="!item.status" v-model="item.cost" outlined :min="0"
                  prepend-inner-icon="mdi-clipboard-check-outline" class="al-r" type="number"
                  @click:prepend-inner="copyPrice(item, index)" hide-details @focus="$event.target.select()" dense>
                </v-text-field>
                <span v-else>{{ (item.qty * item.cost) | currency }}</span>
              </td>
              <td class="text-right">
                {{ (item.qty * item.cost) | currency }}
              </td>
              <td v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.serializeProduct">
                <v-btn v-if="!item.receivedAt" icon @click="receivedItem(item, index)">
                  <v-icon>mdi-phone-return</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card v-if="totalQty > 0" outlined tile>
        <v-card-text>
          <div class="text-left">
            <span class="font-weight-medium">{{ totalQty }}</span> Total Item
          </div>
        </v-card-text>
      </v-card>
    </v-card>
    <v-dialog v-model="returnPhoneDialog" persistent max-width="500px">
      <v-card v-if="returnPhoneDialog">
        <ModelTitle title="Mark Return" @close="close()" />
        <v-card-text class="text-center mt-5">
          <h2>{{ returnItem.name }}</h2>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col v-if="returnItem.imeiNumber">{{
              returnItem.imeiNumber
            }}</v-col>
            <v-col class="text-right">{{ returnItem.cost | currency }}</v-col>
          </v-row>
          <v-textarea outlined hide-details dense auto-grow v-model="returnItem.returnNote" label="Return Note">
          </v-textarea>
        </v-card-text>
        <v-divider> </v-divider>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn color="blue darken-1" text @click="close()">Close</v-btn>
          <v-btn color="blue darken-1" :loading="loading" dark @click="submit()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const ReturnPhone = () => import("./ReturnPhone");
import purchaseService from "@/modules/Inventory/Purchase/service.js";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      returnPhoneDialog: false,
      returnItem: null,
      removeLoading: false,
      loading: false,
      headers: [],
      serialStatus: ['available', 'onHold', 'repair'],
      search: null,
    };
  },
  components: {
    ReturnPhone,
  },
  created() {
    this.headers.push({ text: "", width: "30px" },
      {
        text: "Name",
        align: "left",
        value: "name",
        sortable: false,
      }
    )

    if (this.currentBusiness?.metadata?.serializeProduct) {
      this.headers.push(
        {
          text: "Serial Number",
          align: "left",
          value: "imeiNumber",
          sortable: false,
        },
      )

    }

    this.headers.push(
      {
        text: `${this.currentBusiness?.metadata?.boxCountOnInventory ? 'Boxes' : 'Qty'}`,
        align: "center",
        value: "qty",
        width: "100",
        sortable: false,
      },
    )

    if (this.currentBusiness?.metadata?.updateSerializeProductStatus){
      this.headers.push({
        text: 'Status',
        align: "center",
        value: "status",
        // width: "100",
        sortable: false,
      }) 
    }

    if (this.currentBusiness?.metadata?.boxCountOnInventory) {
      this.headers.push(
        {
          text: "Unit Count",
          align: "right",
          value: "unitCountInBox",
          sortable: false,
        }
      );
    }

    this.headers.push(

      {
        text: "Amount",
        align: "right",
        value: "cost",
        sortable: false,
      },
      {
        text: "Total",
        align: "right",
        value: "total",
        sortable: false,
      },
      { text: "", width: "30px" })

    if (this.currentBusiness?.metadata?.serializeProduct) {
      this.headers.push({ text: "", width: "30px" })
    }

  },
  computed: {
    ...mapGetters("purchase", ["PurchaseDetails", "totalQty"]),
    ...mapGetters("global", [
      "currentUser",
      "checkRightStatus",
      "currentBusiness",
    ]),
    totalQty() {
      let total = 0;
      this.PurchaseDetails.map((row) => {
        total += Math.abs(parseFloat(row.qty));
      });
      return total;
    },
  },
  methods: {
    ...mapActions("purchase", [
      "DeletePurchaseDetailRow",
      "SetEditImeiNumberList",
      "SetImeiNumberDialog",
      "loadPurchase",
      "AddPurchaseDetail",
      "CopyPriceToAll",
    ]),
    fix() {
      console.log('this.PurchaseDetails', this.PurchaseDetails)
      // <div>{{ item.boxQty  }}</div>
      // <div>/{{ item.price *  item.unitCountInBox}} = {{ item.price *  item.unitCountInBox * item.boxQty }}</div>
      this.PurchaseDetails.map(row => {
        row.qty = row.boxQty
        row.cost = parseFloat(row.cost) * parseFloat(row.unitCountInBox)
      })
    },
    checkPrice(item) {
      let total = 0
      if (this.currentBusiness?.metadata?.boxCountOnInventory && item.unitCountInBox > 0) {
        total = ((parseInt(item.boxQty) * parseInt(item.unitCountInBox)) * parseFloat(item.cost)).toFixed(2)
        item.qty = parseInt(item.boxQty) * parseInt(item.unitCountInBox)
      } else {
        total = (parseFloat(item.cost) * parseFloat(item.qty)).toFixed(2)
      }
      item.total = parseFloat(total)
    },
    activeColor(item) {
      let result = "transparent";
      if (item.receivedAt) result = "#FFFDE7";
      return result;
    },
    submit() {
      this.loading = true;
      this.returnItem.receivedAt = this.$moment();
      // console.log("items receive", this.returnItem);
      return purchaseService
        .updateDetailRow(this.returnItem.id, this.returnItem)
        .then((result) => {
          if (result.status) {
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "success",
                title: "Item Return Save!",
              });
            this.loading = false;
            this.loadPurchase(this.returnItem.PurchaseId);
            this.close();
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            .fire({
              icon: "error",
              title: err.data.message,
            });
        });
    },
    remove(item) {
      let tempData = { ...item };
      tempData.receivedAt = null;
      tempData.returnNote = null;
      this.$swal({
        title: "Are you sure?",
        text: `You want to mark item not received`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.value) {
          this.removeLoading = true;
          return purchaseService
            .updateDetailRow(tempData.id, tempData)
            .then((result) => {
              if (result.status) {
                this.$swal
                  .mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  .fire({
                    icon: "success",
                    title: "Item Return Removed!",
                  });
                this.removeLoading = false;
                this.loadPurchase(tempData.PurchaseId);
                this.close();
              }
            })
            .catch((err) => {
              console.log('{ ...food }', err)
              this.removeLoading = false;
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "error",
                  title: "Something want wrong, we are wroking on it.",
                });
            });
        }
      });
    },
    close() {
      this.returnItem = null;
      this.returnPhoneDialog = false;
    },
    receivedItem(item) {
      this.returnItem = item;
      this.returnPhoneDialog = true;
    },
    changeIMEI(item, index) {
      this.SetEditImeiNumberList({ type: "update", item: item, index: index });
      this.SetImeiNumberDialog(true);
    },
    deleteItem(item, index) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to remove ${item.name}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.DeletePurchaseDetailRow(index);
        }
      });
      // confirm('Are you sure you want to delete this item?') && this.invoice.invoiceDetails.splice(index, 1)
    },
    copyPrice(item, index) {
      // console.log("item", item);
      this.$swal({
        title: "Change Price To All",
        text: `You want to change all ${item.name
          } price to ${this.$options.filters.currency(item.cost)}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.CopyPriceToAll(index);
        }
      });
    },
    async insertReturn(val) {
      let tempSerialNumberList = [];

      await Promise.all(
        this.PurchaseDetails.map((row) => {
          tempSerialNumberList = tempSerialNumberList.concat(row.imeiNumber);
        })
      );

      let index = tempSerialNumberList.indexOf(val.imeiNumber);

      if (index != -1) {
        this.$swal({
          title: "Already exist!",
          text: `${val.imeiNumber} is already in this list`,
          icon: "warning",
        });
        // this.$refs.serialNumber.focus();
      } else {
        this.AddPurchaseDetail({
          ProductId: val.ProductId,
          name: val.name,
          price: Math.abs(val.returnPrice),
          qty: -1,
          total: val.cost * -1,
          cost: val.cost,
          imeiNumber: val.imeiNumber,
          lock: true,
        });
      }
    },
  },
};
</script>

<style scoped></style>
