// import _ from "lodash";
import purchaseService from '@/modules/Inventory/Purchase/service.js'

export default {
  namespaced: true,
  state: {
    editPurchaseId: null,
    trackingNumber: null,
    editImeiNumberList: {
      type: 'new'
    },
    selectedCustomer: null,
    selectedProduct: {
      name: '',
      Bar: {
        qty: 0,
      }
    },
    imeiNumberDialog: false,
    Purchase: {
      date: null,
      VendorId: null,
      note: null,
      referenceNumber: null
    },
    PurchaseDetails: [],
  },
  getters: {
    getEditPurchaseId(state) {
      return state.editPurchaseId
    },
    getEditImeiNumberList(state) {
      return state.editImeiNumberList
    },
    getSelectedProduct(state) {
      return state.selectedProduct
    },
    getImeiNumberDialog(state) {
      return state.imeiNumberDialog
    },
    getPurchaseDate(state) {
      return state.Purchase.date
    },
    getPurchaseNote(state) {
      return state.Purchase.note
    },
    getPurchaseReferenceNumber(state) {
      return state.Purchase.referenceNumber
    },
    getSelectedCustomer(state) {
      return state.selectedCustomer
    },
    Purchase(state) {
      return state.Purchase
    },
    purchaseTotal(state) {
      let total = 0;
      state.PurchaseDetails.map(row => {
        if (!row.delete) {
          total += row.qty * parseFloat(row.cost);
        }
      });
      return total;
    },
    totalQty(state) {
      let total = 0;
      if (state.PurchaseDetails) {
        state.PurchaseDetails.map(row => {
          if (!row.delete) total += parseFloat(row.qty);
        });
      }
      return total;
    },
    PurchaseDetails(state) {
      return state.PurchaseDetails
    }
  },
  actions: {
    CopyPriceToAll({
      commit
    }, index) {
      commit('COPY_PRICE_TO_ALL', index)
    },
    SetVendor({
      commit
    }, payload) {
      commit('SET_VENDOR', payload)
    },
    UpdatePurchaseDetail({
      commit
    }, payload) {
      commit('UPDATE_PURCHASE_DETAIL', payload)
    },
    SetEditImeiNumberList({
      commit
    }, payload) {
      commit('SET_EDIT_IMEI_NUMBER_LIST', payload)
    },
    SetSelectedProduct({
      commit
    }, payload) {
      // console.log('payload', payload)
      commit('SET_SELECTED_PRODUCT', payload)
    },
    SetImeiNumberDialog({
      commit
    }, payload) {
      commit('SET_IMEI_NUMBER_DIALOG', payload)
    },
    SetEditPurchaseId({
      commit
    }, payload) {
      commit('SET_EDIT_PURCHASE_ID', payload)
    },
    loadPurchase({
      commit
    }, id) {
      return purchaseService.getById(id).then(response => {
        console.log("purchase", response);
        commit('LOAD_PURCHASE', response.data)
        // this.loadPurchase(response.data);

        this.id = response.data.id
        // this.SetEditInvoiceId(response.data.id);

        return response;
      });

    },
    ResetPurchase({
      commit
    }) {
      commit('RESET_PURCHASE')
    },
    SetPurchaseDate({
      commit
    }, payload) {
      commit('SET_PURCHASE_DATE', payload)
    },
    SetPurchaseDueDate({
      commit
    }, payload) {
      commit('SET_PURCHASE_DUE_DATE', payload)
    },
    SetPurchaseNote({
      commit
    }, payload) {
      commit('SET_PURCHASE_NOTE', payload)
    },
    SetPurchaseReferenceNumber({
      commit
    }, payload) {
      commit('SET_PURCHASE_REFERENCE_NUMBER', payload)
    },
    SetTrackingNumber({
      commit
    }, payload) {
      commit('SET_TRACKING_NUMBER', payload)
    },
    SetSelectedCustomer({
      commit
    }, payload) {
      commit('SET_SELECTED_CUSTOMER', payload)
    },
    DeletePurchaseDetailRow({
      commit
    }, payload) {
      commit('DELETE_PURCHASE_DETAIL_ROW', payload)
    },
    AddPurchaseDetail({
      commit
    }, payload) {
      commit('ADD_PURCHASE_DETAIL', payload)
    }
  },
  mutations: {
    async COPY_PRICE_TO_ALL(state, index) {
      const sourcePriceRow = state.PurchaseDetails[index]
      await Promise.all(
        state.PurchaseDetails.map(row => {
          // // console.log('change price', row.ProductId,  sourcePriceRow.ProductId)
          if (row.ProductId === sourcePriceRow.ProductId) {
            row.cost = sourcePriceRow.cost;
            row.total = sourcePriceRow.cost * sourcePriceRow.qty;
          }
        })
      );
    },
    SET_VENDOR(state, payload) {
      state.Purchase.VendorId = payload.id
    },
    UPDATE_PURCHASE_DETAIL(state, payload) {
      state.PurchaseDetails[payload.index].imeiNumbers = payload.imeiNumbers;
      state.PurchaseDetails[payload.index].qty = payload.qty
      state.PurchaseDetails[payload.index].cost = payload.cost
      state.PurchaseDetails[payload.index].total = payload.total
    },
    SET_EDIT_IMEI_NUMBER_LIST(state, payload) {
      state.editImeiNumberList = payload
    },
    SET_SELECTED_PRODUCT(state, payload) {
      state.selectedProduct = payload
    },
    SET_IMEI_NUMBER_DIALOG(state, payload) {
      state.imeiNumberDialog = payload
    },
    RESET_PURCHASE(state) {
      state.Purchase = {
        date: null,
        VendorId: null,
        note: null,
        referenceNumber: null
      }
      state.PurchaseDetails = []
    },
    LOAD_PURCHASE(state, payload) {
      state.Purchase = {
        ...state.Purchase,
        ...payload,
      }
      // state.Purchase.Vendor = payload.Vendor
      // state.Purchase.date = payload.date
      // state.Purchase.note = payload.note
      state.PurchaseDetails = payload.PurchaseDetails
    },
    SET_EDIT_PURCHASE_ID(state, payload) {
      state.editPurchaseId = payload
    },
    SET_PURCHASE_DATE(state, payload) {
      state.Purchase.date = payload
    },
    SET_PURCHASE_DUE_DATE(state, payload) {
      state.Purchase.dueDate = payload
    },
    SET_PURCHASE_NOTE(state, payload) {
      state.Purchase.note = payload
    },
    SET_PURCHASE_REFERENCE_NUMBER(state, payload) {
      state.Purchase.referenceNumber = payload
    },
    SET_TRACKING_NUMBER(state, payload) {
      state.trackingNumber = payload
    },
    SET_SELECTED_CUSTOMER(state, payload) {
      state.selectedCustomer = payload
      state.Purchase.CustomerId = payload.id
    },
    DELETE_PURCHASE_DETAIL_ROW(state, payload) {
      const temp = state.PurchaseDetails[payload]
      temp.delete = true
      state.PurchaseDetails.splice(payload, 1, temp)
      // state.PurchaseDetails.splice(payload, 1);
    },
    ADD_PURCHASE_DETAIL(state, payload) {
      state.PurchaseDetails.push(payload)
    }
  },
}