<template>
  <v-container grid-list-lg fluid>
    <v-layout column>
      <v-flex>
        <v-card outlined>
          <v-toolbar dense flat :color="$style.purchase.titlebar">
            <v-toolbar-title> Create Purchase </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-row justify="end">
              <v-col class="shrink">
                <v-btn
                  color="info"
                  small
                  :loading="loading"
                  :disabled="PurchaseDetails.length === 0"
                  @click="savePurchase"
                  >{{ purchaseTotal | currency }} Save</v-btn
                >
              </v-col>
              <v-col class="shrink" v-if="!$vuetify.breakpoint.xsOnly">
                <v-btn small text @click.stop.prevent="cancel">Cancel</v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-card-text>
            <v-layout row wrap>
              <v-flex grow>
                <AutoComplete
                  v-model="selectedVendor"
                  :initValue="initVendor"
                />
              </v-flex>
              <v-flex shrink>
                <DateSelector v-model="purchaseDate" />
              </v-flex>
              <v-flex>
                <v-text-field label="Reference Number" outlined dense hide-details v-model="purchaseRefNumber"></v-text-field>
              </v-flex>
              <v-flex shrink>
                <v-btn color="info" height="40px" @click="noteDialog = true">
                  add Note
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex>
        <ProductSearchBar @submit="addRow" />
      </v-flex>
      <v-flex>
        <Cart />
      </v-flex>
    </v-layout>
    <v-dialog persistent v-model="noteDialog" width="500">
      <v-card>
        <ModelTitle title="Add Note" @close="noteDialog = false" />
        <v-container grid-list-md>
          <v-layout row align-center>
            <v-flex>
              <v-textarea
                outlined
                dense
                hide-details
                auto-grow
                v-model="purchaseNote"
                :label="$t('labels.note')"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="noteDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import purchaseService from "../service";
import vendorService from "@/modules/Inventory/Vendor/service.js";
import ProductSearchBar from "@/modules/Inventory/Purchase/components/ProductSearchBar";
import AutoComplete from "@/modules/Inventory/Vendor/components/AutoComplete";
import DateSelector from "@/components/DateSelector";
// import productService from '@/modules/Product/service.js'
import Cart from "@/modules/Inventory/Purchase/components/Cart";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "create-purchase",
  data() {
    return {
      loading: false,
      selectedVendor: null,
      dialogCost: 0,
      editIndex: null,
      createDialog: false,
      noteDialog: false,
      initVendor: null,
      search: null,
      focusable: false,
      serialNumber: null,
      selectedProduct: null,
      products: [],
      headers: [
        {
          text: "Name",
          align: "left",
          value: "productDetail",
          sortable: true,
        },
        {
          text: "Serial Number",
          align: "left",
          value: "serialNumber",
          sortable: true,
        },
        {
          text: "Quantity",
          align: "center",
          value: "qty",
          sortable: true,
        },
        {
          text: "Amount",
          align: "right",
          value: "rate",
          sortable: true,
        },
        {
          text: "Total",
          align: "right",
          value: "total",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    selectedVendor(val) {
      this.SetVendor(val);
    },
  },
  components: {
    Cart,
    ProductSearchBar,
    AutoComplete,
    DateSelector,
  },
  async created() {
    document.title = "smplsale - New Purchase";
    if (this.$route.query && this.$route.query.VendorId) {
      const vendor = await vendorService.getById(this.$route.query.VendorId);
      this.initVendor = vendor.data;
    }
    const obj = this.$route.params;
    if (obj && Object.keys(obj).length !== 0) {
      // console.log('this.$router', this.$route.params)
      let list = obj;
      list.ImeiNumber.map((x) => {
        const row = {
          ProductId: list.Product.id,
          name: list.Product.name,
          imeiNumber: x,
          cost: list.Product.cost,
          total: list.Product.cost,
          qty: 1,
          Product: list.Product,
        };
        this.AddPurchaseDetail(row);
      });
    }
    this.SetPurchaseDate(this.$moment().toISOString());
  },
  computed: {
    ...mapGetters("purchase", [
      "PurchaseDetails",
      "Purchase",
      "purchaseTotal",
      "getImeiNumberDialog",
			"getPurchaseDate",
      "getPurchaseNote",
      "getPurchaseReferenceNumber"
    ]),
    ...mapGetters("global", ["currentBusiness"]),
    purchaseDate: {
      get: function () {
        return this.getPurchaseDate;
      },
      set: function (val) {
        this.SetPurchaseDate(val);
      },
    },
    purchaseNote: {
      get: function () {
        return this.getPurchaseNote;
      },
      set: function (val) {
        this.SetPurchaseNote(val);
      },
    },
    purchaseRefNumber: {
      get: function () {
        return this.getPurchaseReferenceNumber;
      },
      set: function (val) {
        this.SetPurchaseReferenceNumber(val);
      },
    },
  },
  methods: {
    ...mapActions("purchase", [
      "AddPurchaseDetail",
      "SetPurchaseDate",
      "SetVendor",
      "SetImeiNumberDialog",
      "SetSelectedProduct",
      "SetEditImeiNumberList",
      "ResetPurchase",
      "SetPurchaseNote",
      "SetPurchaseReferenceNumber"
    ]),
    cancel() {
      this.ResetPurchase();
      this.$router.go(-1);
    },
    savePurchase() {
      if (!this.Purchase.VendorId) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: "Vendor is not selected!",
          });
      } else {
        if (this.currentBusiness.metadata && this.currentBusiness.metadata.lockPurchaseReferenceNumber && (!this.purchaseRefNumber || this.purchaseRefNumber.length < 4)) {
          this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: "Please enter reference number!",
          });
          return true
        }
        this.loading = true;
        if (!this.purchaseDate) {
          this.SetPurchaseDate(this.$moment().toISOString());
        }
        let newPurchase = this.Purchase;

        var filterPurchaseDetail = this.PurchaseDetails.filter(function (item) {
          if (!item.delete) {
            return item;
          }
        });

        // for (let index = 0; index < this.PurchaseDetails.length; index++) {
        //   const detail = this.PurchaseDetails[index];
        //   if (detail.delete) {
        //     this.PurchaseDetails.splice(index, 1)
        //   }
        // }
        //  // console.log('PurchaseDetails', this.PurchaseDetails)
        newPurchase.PurchaseDetails = filterPurchaseDetail;
        return purchaseService
          .create(newPurchase)
          .then(() => {
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
              })
              .fire({
                icon: "success",
                title: "Purchase created!",
              });
            // productService.resetCache()
            this.cancel();
            // this.ResetPurchase();
            // this.$router.push("/inventory/purchase/list");
          })
          .catch((error) => {
            // console.log("errorerror", error);
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
              })
              .fire({
                icon: "error",
                title: error.data.message,
              });
          });
      }
    },
    addRow(val) {
      // console.log("in create add row", val);
      if (val.trackSerialNumber) {
        this.SetEditImeiNumberList({
          type: "new",
        });
        this.SetSelectedProduct(val);

        this.SetImeiNumberDialog(true);
      } else {
        this.AddPurchaseDetail({
          ProductId: val.id,
          name: val.Bar.name,
          cost: val.Bar.cost,
          total: val.Bar.total,
          qty: val.Bar.qty,
          boxQty: val.Bar.boxQty,
          unitCountInBox: val.unitCountInBox,
          Product: val,
        });
        // this.emptyEntryBar();
      }
    },
  },
};
</script>

<style scoped></style>
