<template>
  <router-view></router-view>
</template>

<script>
import RegisterStoreModule from "@/mixins/RegisterStoreModule";
import purchseService from "./store.js";

export default {
  name: "purchase",
  mixins: [RegisterStoreModule],

  data() {
    return {};
  },
  created() {
    this.registerStoreModule("purchase", purchseService);
  }
};
</script>
